import { ActionIcon, Button, lighten } from '@mantine/core'
import { IconBrandDiscord } from '@tabler/icons-react'
import React, { ReactElement } from 'react'

import config from '@/config'

export const DiscordButton = (): ReactElement => {
    return (
        <Button
            color='discord'
            radius='md'
            variant='filled'
            leftSection={<IconBrandDiscord size='1rem' />}
            component='a'
            href={config.discordInviteLink}
            target='_blank'
        >
            Join Discord
        </Button>
    )
}

export function DiscordButtonSmall() {
    return (
        <ActionIcon
            sx={(theme) => ({
                color: '#fff',
                backgroundColor: '#5865F2',
                '&:hover': {
                    backgroundColor: lighten('#5865F2', 0.05),
                },
            })}
            component='a'
            href={config.discordInviteLink}
            target='_blank'
        >
            <IconBrandDiscord size='1rem' />
        </ActionIcon>
    )
}
