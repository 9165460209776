import dynamic from 'next/dynamic'
import React from 'react'

const DynCookieDialog = dynamic(() => import('./CookieDialog').then((mod) => mod.CookieDialog), {
    ssr: false,
})

// const DynDonateModal = dynamic(() => import('./DonateModal').then((mod) => mod.DonateModal), {
//     ssr: false,
// })

const DynUpdateScriptModel = dynamic(
    () => import('./UpdateScriptModal').then((mod) => mod.UpdateScriptModal),
    {
        ssr: false,
    }
)

export function UseEvents() {
    return (
        <>
            <DynCookieDialog />
            {/*<DynDonateModal />*/}
            <DynUpdateScriptModel />
        </>
    )
}

export default UseEvents
