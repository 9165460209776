import { ActionIcon, Switch, useMantineTheme } from '@mantine/core'
import { IconAdCircle, IconAdCircleFilled, IconAdCircleOff } from '@tabler/icons-react'
import React from 'react'

import { useAds } from '@/provider/useAds'

export const AdsSwitch = () => {
    const theme = useMantineTheme()
    const adsContext = useAds()

    return (
        <Switch
            m='xs'
            checked={adsContext.isEnable}
            onChange={() => {
                adsContext.toggleAds()
            }}
            size='md'
            color={'dark'}
            onLabel={<IconAdCircle size='1rem' stroke={2.5} color={theme.colors.green[4]} />}
            offLabel={<IconAdCircleOff size='1rem' stroke={2.5} color={theme.colors.red[6]} />}
            label='Ads Enabled'
            description='Help the site with Ads!'
        />
    )
}

export const AdsToggleButton = () => {
    const adsContext = useAds()

    return (
        <ActionIcon
            size='lg'
            variant='light'
            onClick={adsContext.toggleAds}
            color={adsContext.isEnable ? 'green' : 'pink'}
        >
            {adsContext.isEnable ? (
                <IconAdCircleFilled size='1.5rem' />
            ) : (
                <IconAdCircleOff size='1.5rem' />
            )}
        </ActionIcon>
    )
}
