import { useEffect, useState } from 'react'

const useIsInsidePwa = () => {
    const [isInsidePwa, setIsInsidePwa] = useState(false)
    useEffect(() => {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            setIsInsidePwa(true)
        }
    }, [])
    return isInsidePwa
}

export default useIsInsidePwa
