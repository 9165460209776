import {
    AppShell,
    Avatar,
    Divider,
    Loader,
    NavLink,
    ScrollArea,
    Text,
    useMantineTheme,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import {
    IconApps,
    IconDownload,
    IconFileText,
    IconHeart,
    IconQuestionMark,
    IconWorld,
} from '@tabler/icons-react'
import React, { PropsWithChildren } from 'react'

import { AdsSwitch } from '@/components/ads/AdsSwitch'
import DiscordWithCounts from '@/components/AppShellComponents/DiscordWithCounts'
import config from '@/config'
import { useAds } from '@/provider/useAds'
import useBeforeInstallPrompt from '@/provider/useBeforeInstallPrompt'

import classes from './NavLink.module.css'

const width = 300

export function AppNavbar({ opened }: PropsWithChildren<{ opened: boolean }>) {
    const theme = useMantineTheme()
    const isBreakpointSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

    const { prompt, showInfoModal, isPrompting, isAvailable } = useBeforeInstallPrompt()
    const adsContext = useAds()

    return (
        <AppShell.Navbar
            p='sm'
            sx={(theme, u) => {
                if (!isBreakpointSm)
                    return {
                        width: `${width}px`,
                        transform: `translateX(${!opened ? '100vw' : `calc(100vw - ${width}px)`})`,
                        transition: 'transform 0.3s ease-in-out',
                        right: 0,
                        [u.dark]: {
                            borderLeft: `1px solid ${theme.colors.dark[4]}`,
                        },
                        [u.light]: {
                            borderLeft: `1px solid ${theme.colors.gray[4]}`,
                        },
                    }
                return {}
            }}
            hidden={!opened}
        >
            <AppShell.Section grow component={ScrollArea} mx='-xs' px='xs'>
                <DiscordWithCounts />
                {!adsContext.adsHidden && (
                    <NavLink
                        label='Donate'
                        description='Crypto donations'
                        active
                        color='red'
                        variant='subtle'
                        component='a'
                        className={classes.navLink}
                        href={config.donate_link}
                        leftSection={<IconHeart size='1rem' stroke={1.5} />}
                    />
                )}

                <NavLink
                    label='Get UserScript'
                    description='Bypass Linkvertise Faster'
                    component='a'
                    active
                    color='teal'
                    variant='subtle'
                    className={classes.navLink}
                    href='/how-to-install-userscript'
                    leftSection={<IconDownload size='1rem' stroke={1.5} />}
                />
                {isAvailable && (
                    <NavLink
                        label='Get as Web-App'
                        active
                        color='indigo'
                        className={classes.navLink}
                        description='Add as Webapp'
                        disabled={isPrompting || showInfoModal}
                        variant='subtle'
                        onClick={prompt}
                        leftSection={
                            isPrompting || showInfoModal ? (
                                <Loader size='1rem' />
                            ) : (
                                <IconApps size='1rem' stroke={1.5} />
                            )
                        }
                    />
                )}
                <NavLink
                    label='Fill out Survey'
                    description='Help us to improve our service'
                    component='a'
                    active
                    color='orange'
                    variant='subtle'
                    target='_blank'
                    className={classes.navLink}
                    href={config.survey_link}
                    leftSection={<IconQuestionMark size='1rem' stroke={1.5} />}
                />
                <NavLink
                    label='Review us on Trustpilot'
                    description='Write us a review on Trustpilot'
                    component='a'
                    active
                    color='green'
                    variant='subtle'
                    className={classes.navLink}
                    href={'https://www.trustpilot.com/review/bypass.city'}
                    leftSection={<Avatar size='1rem' src='/images/trustpilot.png' />}
                />
                <NavLink
                    label='Supported Bypasses'
                    description='Find out which sites are supported!'
                    component='a'
                    className={classes.navLink}
                    href='/supported'
                    leftSection={<IconWorld size='1rem' stroke={1.5} />}
                />

                <NavLink
                    label='Privacy Policy'
                    component='a'
                    className={classes.navLink}
                    href='/privacy-policy'
                    leftSection={<IconFileText size='1rem' stroke={1.5} />}
                />
                <NavLink
                    label='Terms of Service'
                    component='a'
                    className={classes.navLink}
                    href='/terms-of-service'
                    leftSection={<IconFileText size='1rem' stroke={1.5} />}
                />
                <Divider variant='solid' my='md' />
                {!adsContext.adBlockerDetected && !adsContext.adsHidden && <AdsSwitch />}
            </AppShell.Section>

            <AppShell.Section>
                <Text sx={{ color: 'grey' }}>
                    {process.env.NEXT_PUBLIC_ENV_RELEASE_NAME} {' - '}
                    {process.env.NEXT_PUBLIC_ENV_RELEASE_TIME}
                </Text>
            </AppShell.Section>
        </AppShell.Navbar>
    )
}

export default AppNavbar
