import {
    ActionIcon,
    AppShell,
    Badge,
    Burger,
    Divider,
    Flex,
    Group,
    HoverCard,
    Image,
    rem,
    Stack,
    Text,
    useMantineColorScheme,
    useMantineTheme,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconMoonStars, IconPuzzle, IconShieldX, IconSun } from '@tabler/icons-react'
import Link from 'next/link'
import React, { useState } from 'react'

import { AdsToggleButton } from '@/components/ads/AdsSwitch'
import AppNavbar from '@/components/AppShellComponents/AppNavbar'
import { DiscordButtonSmall } from '@/components/Buttons'
import { UseEvents } from '@/components/stats/UseEvents'
import UserscriptSmartToggle from '@/components/usercript/UserscriptSmartToggle'
import { SITE_HOST, useAds, useApi, useDetectUserscript } from '@/provider'

function AppFramework({ children }: React.PropsWithChildren): JSX.Element {
    const { colorScheme, toggleColorScheme } = useMantineColorScheme()
    const theme = useMantineTheme()
    const isBreakpointSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
    const isBreakpointSmall = useMediaQuery(`(max-width:  330px)`)
    const isBreakpointVerySmall = useMediaQuery(`(max-width:  258px)`)
    const { host } = useApi()
    const [opened, setOpened] = useState<boolean>(false)
    const ads = useAds()
    const userscript = useDetectUserscript()

    const goToHome = () => (window.location.href = '/')

    return (
        <AppShell
            navbar={{
                width: 300,
                breakpoint: 'sm',
                collapsed: { mobile: !opened, desktop: true },
            }}
            sx={(theme, u) => ({
                [u.dark]: {
                    backgroundColor: 'transparent',
                },
                [u.light]: {
                    backgroundColor: theme.colors.gray[0],
                },
            })}
            header={{ height: host === SITE_HOST.MIRROR ? 85 : 55 }}
            // footer={<AppFooter />}
        >
            <AppShell.Header className='app-header' m='0'>
                {host === SITE_HOST.MIRROR && (
                    <Flex
                        bg='orange'
                        p='4px'
                        justify='center'
                        align='center'
                        direction='row'
                        wrap='wrap'
                    >
                        <Text color='white' size='sm' ta='center'>
                            This is the official mirror of <a href='https://bypass.city'>bypass.city</a>.{' '}
                            <Link href='/blog/about-mirror'>Why?</Link>
                        </Text>
                    </Flex>
                )}

                <Flex m='sm' justify='flex-end' align='center' direction='row' wrap='wrap'>
                    <Group sx={{ cursor: 'pointer' }} onClick={goToHome}>
                        <Image
                            alt='long text logo'
                            src='/images/logo-long.svg'
                            w={100}
                            h={34}
                            fit='contain'
                            sx={(theme, u) => ({
                                [u.light]: {
                                    filter: 'invert(100%)',
                                },
                            })}
                        />
                    </Group>
                    <Group ml='auto'>
                        {!isBreakpointSm && process.env.NEXT_PUBLIC_ENV_DEV_MODE && (
                            <Badge color='red' size='lg'>
                                DEV MODE
                            </Badge>
                        )}
                        {ads.adBlockerDetected && !isBreakpointSm && (
                            <Badge
                                size='xl'
                                radius='sm'
                                leftSection={
                                    <IconShieldX
                                        style={{
                                            marginTop: '8px',
                                        }}
                                        size={rem(18)}
                                    />
                                }
                                variant='filled'
                                color='pink'
                            >
                                AdBlocker
                            </Badge>
                        )}

                        {ads.adBlockerDetected && isBreakpointSm && !isBreakpointSmall && (
                            <ActionIcon size='lg' color='pink' variant='light'>
                                <IconShieldX size='1.5rem' />
                            </ActionIcon>
                        )}

                        {userscript.detected && !isBreakpointVerySmall && (
                            <HoverCard width={280} shadow='md'>
                                <HoverCard.Target>
                                    <ActionIcon size='lg' color='indigo' variant='light'>
                                        <IconPuzzle size='1.5rem' />
                                    </ActionIcon>
                                </HoverCard.Target>
                                <HoverCard.Dropdown>
                                    <Stack>
                                        <Text size='sm'>
                                            You are using the bypasser userscript! You are on Version{' '}
                                            {userscript.version}
                                        </Text>
                                        <Divider />
                                        <UserscriptSmartToggle />
                                    </Stack>
                                </HoverCard.Dropdown>
                            </HoverCard>
                        )}

                        {!ads.adBlockerDetected && !isBreakpointSmall && !ads.adsHidden && (
                            <AdsToggleButton />
                        )}
                        {!isBreakpointVerySmall && (
                            <ActionIcon
                                onClick={() => toggleColorScheme()}
                                size='lg'
                                sx={(theme, u) => ({
                                    [u.dark]: {
                                        color: theme.colors.yellow[4],
                                        backgroundColor: theme.colors.dark[6],
                                    },
                                    [u.light]: {
                                        color: theme.colors.blue[6],
                                        backgroundColor: theme.colors.gray[0],
                                    },
                                })}
                            >
                                {colorScheme === 'dark' ? (
                                    <IconSun size='1.2rem' />
                                ) : (
                                    <IconMoonStars size='1.2rem' />
                                )}
                            </ActionIcon>
                        )}

                        {!isBreakpointSm && <DiscordButtonSmall />}

                        <Burger
                            opened={opened}
                            onClick={() => {
                                setOpened(!opened)
                            }}
                        />
                    </Group>
                </Flex>
            </AppShell.Header>
            <AppNavbar opened={opened} />
            <UseEvents />
            <AppShell.Main>{children}</AppShell.Main>
        </AppShell>
    )
}

export default AppFramework
