import { Switch } from '@mantine/core'
import React from 'react'

import { useDetectUserscript } from '@/provider/useDetectUserscript'

const UserscriptSmartToggle = () => {
    const { disableSmartFeatures, toggleSmartFeatures } = useDetectUserscript()

    return (
        <Switch
            checked={!disableSmartFeatures}
            onChange={toggleSmartFeatures}
            label='Enable Smart-Features'
            description='Dont use Smart-Features & Automatically Resolve'
        />
    )
}

export default UserscriptSmartToggle
